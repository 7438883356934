import React, { useState, createContext } from "react";

export const ModalVideoContext = createContext();

export const ModalVideoProvider = ({ children }) => {
  const [modalShow, setModalShow] = useState(false);

  const openModal = () => {
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  return (
    <ModalVideoContext.Provider value={{ modalShow, openModal, closeModal }}>
      {children}
    </ModalVideoContext.Provider>
  );
};
