export const theme = {
  primary: "#FF6621",
  secondary: "#136BD3",
  dark: "#3c4a5b",
  breakpoints: {
    sm: 576, //Small screen start
    md: 768, //Medium screen start
    lg: 992, //Large screen start
    xl: 1200 //Extra Large screen start
  }
};
