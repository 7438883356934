import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Container, Navbar } from "react-bootstrap";
import Headroom from "react-headroom";
import { theme } from "../../utils";
import { useWindowSize } from "../../hooks";
import Logo from "../Logo";
import Offcanvas from "../Offcanvas";
import Menu from "../Menu";

const HeadroomStyled = styled(Headroom)`
  .headroom {
    z-index: 9999 !important;
  }
  .navbar {
    transition: all 0.3s ease-out;
  }
  .navbar-brand {
    font-weight: 600;
  }
`;

const NavbarTop = () => {
  const [isPinned, setIsPinned] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize.width < theme.breakpoints.lg) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <>
      {isMobile && (
        <Offcanvas
          className="border-right"
          show={showOffcanvas}
          onHideOffcanvas={() => setShowOffcanvas(false)}
        />
      )}

      <HeadroomStyled
        onPin={() => setIsPinned(true)}
        onUnfix={() => setIsPinned(false)}
        pinStart={500}
      >
        <Navbar
          className={`px-md-0 ${isPinned && "bg-dark border-bottom shadow-sm"}`}
        >
          <Container className="px-sm-3">
            <Navbar.Brand as={Link} to="/">
              <Logo />
            </Navbar.Brand>

            {isMobile ? (
              <div
                css={`
                  font-weight: 600;
                `}
              >
                <div
                  className="d-flex text-white"
                  css={`
                    cursor: pointer;
                  `}
                  onClick={() => setShowOffcanvas(!showOffcanvas)}
                >
                  <span>Menu</span>

                  <svg
                    viewBox="0 0 24 24"
                    css={`
                      height: 1.5rem;
                    `}
                    className="ml-3"
                  >
                    <path
                      d="M0 17h16v2H0zm0-6h16v2H0zm0-6h24v2H0z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-grow-1">
                <Menu
                  className="my-3 ml-auto d-flex align-items-center"
                  header
                />
              </div>
            )}
          </Container>
        </Navbar>
      </HeadroomStyled>
    </>
  );
};

export default NavbarTop;
