import React, { useState, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { ModalVideoContext } from "../../contexts/ModalVideo";
import { theme } from "../../utils";

const ModalStyled = styled(Modal)`
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    max-width: initial;
    max-height: initial;
    margin: 0;
  }
  .modal-content {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    padding-top: 15px;
    background: rgba(0, 0, 0, 0.75);
    @media only screen and (min-width: ${theme.breakpoints.lg}px) {
      padding-top: 30px;
    }
  }
`;

const ReactPlayerStyled = styled(ReactPlayer)`
  opacity: 0;
  transition: all 2s ease-out;
  &.loaded {
    opacity: 1;
  }
`;
const DivStyled = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 400px;
  @media only screen and (min-width: ${theme.breakpoints.md}px) {
    width: 70%;
    height: 650px;
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 1rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: #fff;
  @media only screen and (min-width: ${theme.breakpoints.md}px) {
    right: 2rem;
  }
`;

const CloseButton = (props) => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: currentcolor;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="currentColor"
      ></path>
    </svg>
  </CloseWrapper>
);

const ModalVideo = (props) => {
  const [loading, setLoading] = useState(true);
  const modalVideo = useContext(ModalVideoContext);

  const { datoCmsLanding } = useStaticQuery(graphql`
    query {
      datoCmsLanding {
        videoUrl
      }
    }
  `);

  const handleClose = () => {
    setLoading(true);
    modalVideo.closeModal();
  };

  return (
    <ModalStyled
      {...props}
      size="lg"
      centered
      show={modalVideo.modalShow}
      onHide={modalVideo.closeModal}
    >
      <Modal.Body className="text-center position-relative">
        <CloseButton onClick={handleClose} />

        <div className={`h-100 d-flex align-items-center w-100`}>
          <DivStyled>
            <ReactPlayerStyled
              url={datoCmsLanding.videoUrl}
              width="100%"
              height="100%"
              controls
              className={`mx-auto ${!loading && `loaded`}`}
              onReady={() => setLoading(false)}
            />
          </DivStyled>
        </div>
      </Modal.Body>
    </ModalStyled>
  );
};

export default ModalVideo;
