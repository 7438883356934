import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Section from "../Section";
import Logo from "../Logo";
import Social from "../Social";

import imgMarker from "../../assets/images/marker.png";
import imgPhone from "../../assets/images/phone.png";
import imgCopy from "../../assets/images/copyright.png";

const SectionStyled = styled(Section)`
  flex-shrink: 0;
  a {
  }
`;

const Footer = () => {
  const { datoCmsCommon, datoCmsSocial } = useStaticQuery(graphql`
    query {
      datoCmsCommon {
        location
        phoneNo
        copyright
        menuLinks {
          title
          url
        }
        companyLinks {
          url
          title
        }
      }
      datoCmsSocial {
        facebook
        instagram
        youtube
        twitter
      }
    }
  `);

  const items = [
    {
      title: "Facebook",
      url: datoCmsSocial.facebook,
    },
    {
      title: "Twitter",
      url: datoCmsSocial.twitter,
    },
    {
      title: "YouTube",
      url: datoCmsSocial.youtube,
    },
    {
      title: "Instagram",
      url: datoCmsSocial.instagram,
    },
  ];

  return (
    <>
      <SectionStyled
        className="py-5 border-top text-white"
        css={`
          background: #474869;
        `}
      >
        <Container>
          <div className="d-flex flex-column  align-items-center align-items-lg-start">
            <Link to="/" className="d-block">
              <Logo footer />
            </Link>
          </div>
          <Row>
            <Col lg="4">
              <div className="d-flex flex-column  pr-lg-4">
                <div className="d-flex align-items-start my-4">
                  <img
                    src={imgMarker}
                    css={`
                      width: 17px;
                      height: 20px;
                    `}
                    alt=""
                    className="mr-2"
                  />
                  <div
                    css={`
                      margin-top: -5px;
                    `}
                  >
                    {datoCmsCommon.location}
                  </div>
                </div>
                <div className="d-flex align-items-start mb-4">
                  <img
                    src={imgPhone}
                    css={`
                      width: 20px;
                      height: 20px;
                    `}
                    alt=""
                    className="mr-2"
                  />
                  <div
                    css={`
                      margin-top: -5px;
                    `}
                  >
                    {datoCmsCommon.phoneNo}
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <img
                    src={imgCopy}
                    css={`
                      width: 20px;
                      height: 20px;
                    `}
                    alt=""
                    className="mr-2"
                  />
                  <div
                    css={`
                      margin-top: -5px;
                    `}
                  >
                    {datoCmsCommon.copyright}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="2">
              <div className="d-flex flex-column  align-items-center align-items-lg-start mt-5 mt-lg-0">
                <h5 className="text-white my-3">Menu</h5>
                {datoCmsCommon.menuLinks.map((item, index) => (
                  <div className="mb-3" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      {item.title}
                    </a>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg="2">
              <div className="d-flex flex-column  align-items-center align-items-lg-start  mt-5 mt-lg-0">
                <h5 className="text-white mb-3">Company</h5>
                {datoCmsCommon.companyLinks.map((item, index) => (
                  <div className="mb-3" key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white"
                    >
                      {item.title}
                    </a>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg="4">
              <div className="d-flex flex-column  align-items-center align-items-lg-start pl-lg-4  mt-5 mt-lg-0">
                <h5 className="text-white">Síguenos:</h5>
                <div className="d-flex align-items-center align-items-lg-start pt-4">
                  <Social items={items} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Footer;
