import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Header from "../Header";
import Footer from "../Footer";
import { ModalVideoProvider } from "../../contexts/ModalVideo";
import ModalVideo from "../ModalVideo";
import "./bootstrap-custom.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const MainStyled = styled.main`
  flex: auto;
`;

const Layout = (props) => {
  return (
    <>
      <Helmet>
        <title>everybot</title>
        <meta name="description" content="everybot description" />
      </Helmet>
      <LayoutStyled>
        <ModalVideoProvider>
          <ModalVideo />
          <Header />
          <MainStyled>{props.children}</MainStyled>
          <Footer />
        </ModalVideoProvider>
      </LayoutStyled>
    </>
  );
};

export default Layout;
