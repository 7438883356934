import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Logo = ({ footer, height, className = "", ...rest }) => {
  const { datoCmsCommon } = useStaticQuery(graphql`
    query {
      datoCmsCommon {
        footerLogo {
          fluid(maxWidth: 150) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        headerLogo {
          fluid(maxWidth: 150) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  `);

  return footer ? (
    <Img
      fluid={datoCmsCommon.footerLogo.fluid}
      alt=""
      css={`
        width: 130px;
      `}
      className={`${className}`}
      {...rest}
    />
  ) : (
    <Img
      fluid={datoCmsCommon.headerLogo.fluid}
      alt=""
      css={`
        width: 150px;
      `}
      className={`${className}`}
      {...rest}
    />
  );
};

export default Logo;
