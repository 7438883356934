import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { useStaticQuery, graphql } from "gatsby";
import { Nav } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin, FaWhatsapp } from "react-icons/fa";

import { theme } from "../../utils";

const NavStyled = styled(Nav)`
  .nav-item {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
    a.link {
      cursor: pointer;
      color: #fff;

      font-weight: 600;
      position: relative;

      &:hover,
      &.active {
        color: ${theme.primary};
      }
    }
  }
`;

const Menu = ({
  vertical = false,
  onChangeLink = () => {},
  header = false,
  ...rest
}) => {
  const menuItemsNavHome = [
    { title: "INCIO", link: "intro" },
    { title: "¿POR QUÉ SOMOS GENIALES?", link: "benefit" },
    { title: "PRECIOS", link: "pricing" },
    { title: "CONTÁCTANOS", link: "contact" },
  ];

  const menuItems = menuItemsNavHome;

  const { datoCmsCommon, datoCmsSocial } = useStaticQuery(graphql`
    query {
      datoCmsCommon {
        whatsappPhone
      }
      datoCmsSocial {
        facebook
        linkedin
        whatsapp
      }
    }
  `);

  return (
    <NavStyled {...rest}>
      {menuItems.map((item, index) => (
        <Nav.Item key={index} className={`nav-item ${vertical && `py-2`}`}>
          <Link
            to={item.link}
            className={`link`}
            activeClass="active"
            isDynamic={true}
            spy={true}
            smooth={true}
            onClick={onChangeLink}
          >
            {item.title}
          </Link>
        </Nav.Item>
      ))}
      <Nav.Item className="mr-2 my-2 my-lg-0">
        <a
          href={datoCmsSocial.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookSquare size="1.75rem" color="#fff" />
        </a>
      </Nav.Item>
      <Nav.Item className="mr-2 mb-2 mb-lg-0">
        <a
          href={datoCmsSocial.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin size="1.75rem" color="#fff" />
        </a>
      </Nav.Item>
      <Nav.Item>
        <a
          href={datoCmsSocial.whatsapp}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="d-flex align-items-center text-white">
            <FaWhatsapp size="1.75rem" color="#fff" />
            <div className="ml-1 d-flex flex-column">
              <small
                css={`
                  font-size: 10px;
                `}
              >
                Whatsapp Message
              </small>
              <small>{datoCmsCommon.whatsappPhone}</small>
            </div>
          </div>
        </a>
      </Nav.Item>
    </NavStyled>
  );
};

export default Menu;
